<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		d="M22.5 14.484C22.5 13.3794 21.6046 12.484 20.5 12.484H13.5C10.1863 12.484 7.5 15.1703 7.5 18.484V34.484C7.5 37.7977 10.1863 40.484 13.5 40.484H29.5C32.8137 40.484 35.5 37.7977 35.5 34.484V29.484C35.5 28.3794 34.6046 27.484 33.5 27.484C32.3954 27.484 31.5 28.3794 31.5 29.484V34.484C31.5 35.5886 30.6046 36.484 29.5 36.484H13.5C12.3954 36.484 11.5 35.5886 11.5 34.484V18.484C11.5 17.3794 12.3954 16.484 13.5 16.484H20.5C21.0304 16.484 21.5391 16.2733 21.9142 15.8982C22.2893 15.5231 22.5 15.0144 22.5 14.484Z"
		fill="currentColor"
	/>
	<path
		d="M19.5 31.484C20.2948 31.4844 21.0573 31.1693 21.62 30.608L34.556 17.692C34.6495 17.5981 34.7765 17.5453 34.909 17.5453C35.0415 17.5453 35.1685 17.5981 35.262 17.692L38.8 21.222C38.9876 21.4102 39.2423 21.5159 39.508 21.516C39.639 21.5153 39.7687 21.4895 39.89 21.44C40.264 21.2854 40.5079 20.9207 40.508 20.516V8.516C40.508 7.96371 40.0603 7.516 39.508 7.516H27.508C27.1039 7.51608 26.7395 7.75942 26.5846 8.13269C26.4297 8.50596 26.5147 8.93578 26.8 9.222L30.322 12.742C30.4148 12.8366 30.4672 12.9635 30.468 13.096C30.4677 13.229 30.4144 13.3564 30.32 13.45L17.38 26.362C16.5217 27.2198 16.2646 28.5101 16.7287 29.6313C17.1928 30.7525 18.2866 31.4837 19.5 31.484V31.484Z"
		fill="currentColor"
	/>
</svg>

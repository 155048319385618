import type { BlockType } from '$lib/graphql/types'
import { BlockType as BlockTypeEnum } from '$lib/graphql/enums'

export function getCustomTitle(
	title: string,
	blockType: BlockType,
	position: number,
	parentTitle: string | null,
): string {
	if (blockType === BlockTypeEnum.Day) {
		if (parentTitle != null) {
			return `${parentTitle} - Day ${position + 1}`
		} else if (title != null) {
			return `Day ${position + 1} - ${title}`
		}
		return `Day ${position + 1}`
	}
	return title
}

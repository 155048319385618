<script lang="ts">
	import MoveIcon from '$lib/components/icons/MoveIcon.svelte'
	import Spinner from '$lib/components/loading/Spinner.svelte'
	import blockState from '$src/lib/stores/block-state'
	import { onDestroy } from 'svelte'
	import { twMerge } from 'tailwind-merge'
	import { fixTitleCharacters } from '$lib/utils/title-case'
	import { browser } from '$app/environment'
	import SkeletonBlock from '$lib/components/loading/SkeletonBlock.svelte'
	import Route from './Route.svelte'
	import type { RouteFieldsFragment } from '$lib/queries/fragments/generated/RouteFields'
	import ExpandIcon from '$lib/components/icons/ExpandIcon.svelte'
	import debounce from 'lodash-es/debounce'
	import InViewObserver from '$lib/components/display/InViewObserver.svelte'
	import { stopPropagation } from '$lib/utils/clickHelpers'
	import { sanitizeAllTagsHtml, sanitizeHtml } from '$src/lib/utils/sanitize'

	interface Props {
		title?: string
		description?: string | null
		iconUrl?: string
		bgColor?: string
		imageUrl?: string | null
		blockId?: string | null
		isPublic?: boolean
		isImmutable?: boolean
		hasActions?: boolean
		isLoading?: boolean
		previewBlocks?: string[]
		routeEndId?: string | null
		isMoving?: boolean
		route?: RouteFieldsFragment | null
		routeBlockId?: string | null
		expandedBlockId?: string | null
		openOnFirstClick?: boolean
		isBlockPositionUpdating?: boolean
		numSources?: number
		hideMoreButton?: boolean
		isDndModal?: boolean
		additionalDetails?: string
		isListSection?: boolean
		children?: import('svelte').Snippet
		onexpand?: (blockId: string) => void
		onclick?: (blockId: string) => void
	}

	let {
		title = 'no title available',
		description = 'no description available',
		iconUrl = '/visuals/icons/fork-knife.svg',
		bgColor = 'bg-red-200',
		imageUrl = $bindable(null),
		blockId = null,
		isPublic = false,
		isImmutable = false,
		hasActions = false,
		isLoading = false,
		previewBlocks = [],
		routeEndId = null,
		isMoving = false,
		route = null,
		routeBlockId = null,
		expandedBlockId = null,
		openOnFirstClick = false,
		isBlockPositionUpdating = false,
		numSources = 0,
		hideMoreButton = false,
		additionalDetails = '',
		isDndModal = false,
		isListSection = false,
		children,
		onexpand,
		onclick,
	}: Props = $props()

	let isExpanded = $state(false)
	let sanitizedDescription: string = $state('')
	let sanitizedExpandedDescription = $state('')
	let sanitizedTitle: string = $state('')

	let isPreviewed = $derived(previewBlocks.includes(blockId))

	$effect(() => {
		isExpanded = expandedBlockId === blockId
	})

	$effect(() => {
		if (!browser) return
		// add space
		let cancelled = false
		sanitizeAllTagsHtml(
			description && description != ''
				? fixTitleCharacters(description?.replace(/<\/p><p>/g, ' '))
				: 'no description',
			browser,
			(result) => {
				if (!cancelled) {
					sanitizedDescription = result.trim()
				}
			},
		)

		sanitizeHtml(description, browser, (result) => {
			if (!cancelled) {
				sanitizedExpandedDescription = result.trim()
			}
		})

		sanitizeAllTagsHtml(
			title && title != '' ? fixTitleCharacters(title) : 'no title',
			browser,
			(result) => {
				if (!cancelled) {
					sanitizedTitle = result.trim()
				}
			},
		)
		return () => {
			cancelled = true
		}
	})

	let displayedDescription = $state('')
	$effect(() => {
		displayedDescription = isExpanded ? sanitizedExpandedDescription : sanitizedDescription
	})

	let skeletonWidthTitle = $derived(Math.min(100, Math.max(50, title?.length ?? 0)))
	let skeletonWidth = $derived(Math.min(100, Math.max(50, (description?.length ?? 0) * 2)))

	function handleClick() {
		if (isDndModal) return
		onclick?.(blockId)
	}

	function handleExpand(expandedBlockId: string) {
		if (openOnFirstClick) {
			onclick?.(blockId)
		} else {
			onexpand?.(expandedBlockId)
		}
	}

	const debouncedSetHoverState = debounce(
		(id: string | null) => {
			if (id != null) {
				blockState.setHoveringPlaceId(id)
			}
		},
		100,
		{ leading: true, trailing: false }, // Execute on the leading edge for better UX with hover
	)

	// Then update the handler function
	function handleSetHoverState() {
		debouncedSetHoverState(blockId)
	}

	// Don't forget cleanup
	onDestroy(() => {
		debouncedSetHoverState.cancel()
	})

	function handleUnsetHoverState() {
		// if (isPublic) return
		if (blockId != null) blockState.cleartHoveringPlaceId()
	}

	let computedClassNameTitle = $derived(
		twMerge(
			'focus:outline-none focus:ring-0 font-medium dark:text-brand-gray-2 text-black w-full truncate dark:group-hover:text-white transition-all',
			isPublic && !isPreviewed ? 'blur-small text-brand-gray-2' : 'blur-none',
			isExpanded ? 'text-lg text-wrap break-all' : 'text-xs truncate pr-8',
		),
	)
	let computedClassNameDescription = $derived(
		twMerge(
			'focus:outline-none focus:ring-0 dark:text-brand-gray-3 text-brand-gray-5 w-full dark:group-hover:text-brand-gray-2 transition-all',
			isPublic && !isPreviewed ? 'blur-small text-brand-gray-2' : 'blur-none',
			isExpanded ? 'text-sm mt-1 text-wrap prose-content -mr-8' : 'text-xs truncate pr-8',
		),
	)

	function handleImageLoadError() {
		imageUrl = null
	}

	let isInView = $state(false)

	function handleEnter() {
		isInView = true
	}
</script>

<InViewObserver
	onenter={handleEnter}
	class="w-full flex flex-col items-start overflow-hidden"
	enabled={true}
	id={blockId}
	rootMargin="200px"
>
	{#if isInView || !browser}
		<div class="group w-full flex items-start justify-between my-1 overflow-x-hidden">
			<button
				onclick={stopPropagation(() => handleExpand(blockId))}
				onmouseenter={handleSetHoverState}
				onmouseleave={handleUnsetHoverState}
				disabled={!blockId}
				aria-expanded={isExpanded}
				aria-controls={`block-content-${blockId}`}
				class={`flex items-start text-brand-gray-5 group-hover:text-black dark:text-white group py-1  w-full ${
					isExpanded ? 'mr-8' : 'truncate'
				} ${isDndModal ? 'cursor-grab' : 'cursor-pointer'}`}
			>
				{#if imageUrl != null}
					<span class="relative inline-block flex-shrink-0">
						<img
							class="h-8 w-8 rounded-md object-cover"
							onerror={handleImageLoadError}
							src={imageUrl}
							alt={`Image for ${sanitizedTitle || 'item'}`}
						/>
						<img
							class={`absolute bottom-1 right-0 block translate-x-1/2 translate-y-1/2 transform rounded-full p-[2px] border-[3px] border-brand-gray-5 h-[20px] w-[20px] ${bgColor}`}
							src={iconUrl}
							alt={`Icon for ${sanitizedTitle || 'item'}`}
						/>
					</span>
				{:else}
					<div
						class={`flex flex-shrink-0 items-center justify-center h-8 w-8 rounded-md ${bgColor}`}
					>
						<img class="h-5 w-5" src={iconUrl} alt={`Icon for ${sanitizedTitle || 'item'}`} />
					</div>
				{/if}
				<div class="pl-3 text-start w-full">
					{#if !browser}
						<SkeletonBlock width={`${skeletonWidthTitle}%`} height="12px" class="mb-1" />
						<SkeletonBlock width={`${skeletonWidth}%`} height="12px" class="mb-1" />
					{:else}
						<div class={computedClassNameTitle}>
							{@html sanitizedTitle}
						</div>
						<div class={computedClassNameDescription}>
							{@html displayedDescription}
						</div>
						{#if isExpanded}
							<p class="mt-2 text-sm dark:text-brand-gray-3 text-brand-gray-5">
								{additionalDetails}
							</p>
						{/if}
					{/if}
				</div>
			</button>

			<div class="flex items-center py-2 mr-1 space-x-2">
				{#if isDndModal}
					<div class="items-center space-x-1">
						{#if isLoading && isBlockPositionUpdating}
							<Spinner size="sm" embedded />
						{:else}
							<button class="hover:cursor-grab">
								<MoveIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
							</button>
						{/if}
					</div>
				{:else if hasActions && !isExpanded}
					<div
						class={`items-center space-x-1 dark:bg-brand-gray-5 ${
							isLoading && isBlockPositionUpdating ? 'flex' : 'hidden group-hover:flex'
						}`}
					>
						{#if isLoading && isBlockPositionUpdating}
							<Spinner size="sm" embedded />
						{:else}
							<button
								class="hover:cursor-grab touch-manipulation"
								onclick={stopPropagation(handleClick)}
							>
								<MoveIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
							</button>
							<button
								class="hover:cursor-pointer touch-manipulation"
								onclick={stopPropagation(handleClick)}
							>
								<ExpandIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
							</button>
						{/if}
					</div>
				{:else if !hideMoreButton}
					<div
						class={`items-center space-x-1 flex-shrink-0  ${
							isExpanded ? 'flex' : 'hidden group-hover:flex'
						}`}
					>
						<button
							class="hover:cursor-pointer flex items-center touch-manipulation"
							onclick={stopPropagation(handleClick)}
						>
							<span class="mr-2 text-xs text-brand-gray-5 dark:text-brand-gray-2">more</span>
							<ExpandIcon class="h-4 w-4 text-brand-gray-5 dark:text-brand-gray-2" />
						</button>
					</div>
				{/if}

				{@render children?.()}
			</div>
		</div>

		{#if !isMoving && !isLoading && routeEndId && !isListSection}
			<Route
				blockStartId={blockId}
				blockEndId={routeEndId}
				{route}
				{isPublic}
				{isImmutable}
				{routeBlockId}
			/>
		{/if}
	{/if}
</InViewObserver>

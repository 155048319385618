import type { BlockFieldsFragment } from '../queries/fragments/generated/BlockFields'
import type { MapViewBlockFieldsFragment } from '../queries/fragments/generated/MapViewBlockFields'
import type { PublicBlockFieldsFragment } from '../queries/fragments/generated/PublicBlockFields'
import titleCase from './title-case'

export function getBlockPositionsToUpdate(
	blocks: BlockFieldsFragment[],
	parentId: string,
): { id: string; position: number; parentId: string }[] {
	return blocks
		?.map((block, index) => {
			return {
				id: block.id,
				position: index,
				parentId,
			}
		})
		?.filter((block) => block !== null)
}

export function getBlockType(
	block: BlockFieldsFragment | PublicBlockFieldsFragment | MapViewBlockFieldsFragment,
) {
	if (block?.blockType) return titleCase(block.blockType)
	return 'Block'
}

export function getMapViewColor(block: MapViewBlockFieldsFragment) {
	const type = getBlockType(block)
	switch (type) {
		case 'Destination':
			return 'text-pink-500'
		case 'Place':
			return 'text-red-500'
		case 'Highlight':
			return 'text-yellow-500'
		case 'Custom Region':
			return 'text-orange-500'
		case 'Custom Pin':
			return 'text-purple-500'
		case 'Source':
			return 'text-blue-500'
		case 'List':
			return 'text-green-500'
		default:
			return null
	}
}

export function getIconUrlAndColor(block: BlockFieldsFragment | PublicBlockFieldsFragment) {
	const type = getBlockType(block)
	switch (type) {
		case 'Destination':
			return { url: '/visuals/icons/flag.svg', color: 'bg-pink-400' }
		case 'Place':
			return { url: '/visuals/icons/location-pin.svg', color: 'bg-red-400' }
		case 'Highlight':
			return { url: '/visuals/icons/landscape-1.svg', color: 'bg-yellow-400' }
		case 'Custom Region':
			return { url: '/visuals/icons/select-all.svg', color: 'bg-orange-400' }
		case 'Custom Pin':
			return { url: '/visuals/icons/location-pin.svg', color: 'bg-purple-400' }
		case 'Source':
			return { url: '/visuals/icons/link-chain.svg', color: 'bg-blue-400' }
		case 'List':
			return { url: '/visuals/icons/paginate-heart.svg', color: 'bg-green-400' }
		default:
			return { url: null, color: null }
	}
}

export type LocationLike = {
	lat: number
	lng: number
	type: string
	name: string
	id: string
}

export const BlockLimitPerTrip: number = 200
export const SourceBlockLimitPerTrip: number = 100
export const HighlightBlockLimitPerTrip: number = 100

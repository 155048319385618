<script lang="ts">
	import { getContextClient } from '@urql/svelte'
	import CarIcon from '$src/lib/components/icons/CarIcon.svelte'
	import type { RouteFieldsFragment } from '$lib/queries/fragments/generated/RouteFields'
	import { onMount } from 'svelte'
	import {
		UpsertRouteBlockDocument,
		type UpsertRouteBlockMutation,
		type UpsertRouteBlockMutationVariables,
	} from '$lib/queries/generated/UpsertRoute'
	import { page } from '$app/state'
	import blockState from '$lib/stores/block-state'

	interface Props {
		blockStartId: string
		blockEndId: string
		route?: RouteFieldsFragment | null
		isPublic?: boolean
		isImmutable?: boolean
		routeBlockId?: string | null
		isBlockLoading?: boolean
	}

	let {
		blockStartId,
		blockEndId,
		route = $bindable(null),
		isPublic = false,
		isImmutable = false,
		routeBlockId = $bindable(null),
		isBlockLoading = false,
	}: Props = $props()

	let isLoading = $state(false)
	let triedRoute = $state(false)

	function handleSetHoverState() {
		if (routeBlockId != null) {
			blockState.setHoveringPlaceId(routeBlockId)
		}
	}

	function handleUnsetHoverState() {
		if (routeBlockId != null) blockState.cleartHoveringPlaceId()
	}

	const client = getContextClient()
	const upsertRouteBlock = (vars: UpsertRouteBlockMutationVariables) =>
		client
			.mutation<UpsertRouteBlockMutation, UpsertRouteBlockMutationVariables>(
				UpsertRouteBlockDocument,
				vars,
				{
					requestPolicy: 'cache-first',
				},
			)
			.toPromise()

	const MAX_RETRIES = 1
	const RETRY_DELAY = 100 // 1 second delay between retries

	const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

	onMount(async () => {
		getRoute()
	})

	async function getRoute() {
		if (!route && !isPublic && !isImmutable) {
			isLoading = true
			let retries = 0

			while (retries < MAX_RETRIES) {
				if (tripId) {
					try {
						const res = await upsertRouteBlock({
							tripID: tripId,
							startBlockID: blockStartId,
							endBlockID: blockEndId,
						})

						// if (res?.data?.upsertRouteBlock?.routeAfter?.route) {
						// 	route = res?.data?.upsertRouteBlock?.routeAfter?.route
						// 	routeBlockId = res?.data?.upsertRouteBlock?.routeAfter?.id
						// 	break
						// }
					} catch (error) {
						console.error('Error upserting route block:', error)
						break
					}
				}

				retries++
				if (retries < MAX_RETRIES) {
					await sleep(RETRY_DELAY)
				}
			}

			isLoading = false
		}
		triedRoute = true
	}
	$effect(() => {
		if (!route && !isPublic && !isImmutable && !isBlockLoading) {
			getRoute()
		}
	})
	let tripId = $derived(page?.params?.slug)
</script>

{#if isLoading || (!triedRoute && !route) || route}
	<p
		onmouseenter={handleSetHoverState}
		onmouseleave={handleUnsetHoverState}
		class={`route-component text-[10px] my-1 leading-[10px] text-brand-gray-4 text-start flex items-center justify-start ${
			isLoading ? 'blur-small' : ''
		}`}
	>
		<CarIcon class="w-3 h-3 ml-[10px] mr-6" />
		{#if route && route?.isFound}
			{(route?.distance ?? 0).toFixed(1)} miles, {Math.floor((route?.time ?? 0) / 60)} minutes
		{:else if route?.isFound === false}
			route not possible
		{:else if isLoading}
			- miles, - minutes
		{/if}
	</p>
{/if}

<script lang="ts">
	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		d="M16.7501 13.5C18.8211 13.5 20.5001 11.8211 20.5001 9.75C20.5001 7.67893 18.8211 6 16.7501 6C14.679 6 13.0001 7.67893 13.0001 9.75C13.0001 11.8211 14.679 13.5 16.7501 13.5Z"
		fill="currentColor"
	/>
	<path
		d="M31.4446 13.5C33.5157 13.5 35.1946 11.8211 35.1946 9.75C35.1946 7.67893 33.5157 6 31.4446 6C29.3735 6 27.6946 7.67893 27.6946 9.75C27.6946 11.8211 29.3735 13.5 31.4446 13.5Z"
		fill="currentColor"
	/>
	<path
		d="M16.7501 20.6926C15.2332 20.6926 13.8658 21.6064 13.2854 23.0078C12.7051 24.4092 13.0262 26.0223 14.099 27.0946C15.1717 28.167 16.7849 28.4875 18.1861 27.9066C19.5873 27.3257 20.5006 25.9579 20.5 24.441C20.4976 22.3712 18.8199 20.6941 16.7501 20.6926Z"
		fill="currentColor"
	/>
	<path
		d="M31.4442 20.6926C29.9272 20.692 28.5593 21.6054 27.9785 23.0068C27.3977 24.4082 27.7185 26.0214 28.7911 27.0941C29.8638 28.1668 31.477 28.4875 32.8784 27.9067C34.2798 27.3259 35.1933 25.958 35.1927 24.441C35.1903 22.3718 33.5134 20.6949 31.4442 20.6926Z"
		fill="currentColor"
	/>
	<path
		d="M16.7501 35.3852C14.6791 35.3852 13.0001 37.0641 13.0001 39.1352C13.0001 41.2063 14.6791 42.8852 16.7501 42.8852C18.8212 42.8852 20.5001 41.2063 20.5001 39.1352C20.4977 37.0651 18.8202 35.3876 16.7501 35.3852V35.3852Z"
		fill="currentColor"
	/>
	<path
		d="M31.4442 35.3852C29.9273 35.3846 28.5595 36.2979 27.9786 37.6991C27.3978 39.1003 27.7182 40.7135 28.7906 41.7863C29.863 42.859 31.476 43.1801 32.8774 42.5998C34.2789 42.0195 35.1927 40.652 35.1927 39.1352C35.1903 37.0657 33.5137 35.3884 31.4442 35.3852V35.3852Z"
		fill="currentColor"
	/>
</svg>
